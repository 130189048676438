import {computed, defineComponent,getCurrentInstance,toRefs, onMounted, ref,onActivated,reactive} from "vue";
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import { _Client } from "@/api-client";
import { ETLevelEnum, ExaminerCertificationStatusEnum, ExaminerLastSessionStatusEnum, ExaminerMonitorStatusEnum, ExaminerProfileStatusEnum, GetAllRegionRep, ModuleEnum, PagedResultOfSerchCertificationRecordsRep, SearchCertificationRecordsQuery, SystemStatusEnum, WorkStatusEnum,GetCertificationRecordLogByIdQuery,AuditEntityLogRep } from "@/api-client/client";
import Pagination from '@/components/Pagination/index.vue'
import { useRouter } from "vue-router";
import moment from "moment";
import component from "*.vue";
import {addTimezoneByDbNameForce} from "@/utils/DateExtensions";
import { LogDetailModel, LogModel } from '@/api-client/modal';
import { checkAccess } from '@/utils/common';
import LogDetail from '@/components/LogDetail/index.vue'

export default defineComponent({
    components: {
        DownOutlined,
        UpOutlined,
        Pagination,
        LogDetail
    },
    setup() {
        const timeMoment = moment
        const internalInstance = getCurrentInstance();
        const tableColumns = [
            {
                title: 'Region',
                dataIndex: 'region',
                width: 120
            },
            {
                title: 'Examiner No.',
                dataIndex: 'examinerNo',
                width: 160
            },
            {
                title: 'Examiner Name',
                dataIndex: 'examinerName',
                width: 160
            },
            {
                title: 'Initial',
                dataIndex: 'examinerInitial',
                width: 100
            },
            {
                title: 'Certification Date',
                dataIndex: 'certificationDate',
                slots: { customRender: 'certificationData' },
                width: 170
            },
            {
                title: 'Certification Due Date',
                dataIndex: 'certificationDueData',
                width: 170
            },
            {
                title: 'Profile Status',
                dataIndex: 'examinerProfileStatus',
                width: 120
            },
            {
                title: 'Monitor Status',
                dataIndex: 'examinerMonitorStatus',
                width: 120
            },
            {
                title: 'Last Session Status',
                dataIndex: 'examinerLastSessionStatus',
                width: 180
            },
            {
                title: 'Certification Status',
                dataIndex: 'examinerCertificationStatus',
                width: 170
            },
            {
                title: 'Training Date',
                dataIndex: 'trainingDate',
                width: 130
            },
            {
                title: 'Follow-Up Action',
                dataIndex: 'followUpAction',
                width: 170
            },
            {
                title: 'ET Level',
                dataIndex: 'etLevel',
                width: 130
            },
            {
                title: 'System Status',
                dataIndex: 'certificationSystemStatus',
                width: 140
            },
            {
                title: 'Action',
                dataIndex: 'action',
                fixed: 'right',
                width: 200
            }
        ]
        const { push } = useRouter();
        const searchParamsIsDisplay = ref(false)

        const moduleEnum = ModuleEnum;
        const etLevel=ETLevelEnum;
        const workStatus=WorkStatusEnum;
        const systemStatus=SystemStatusEnum;
        const examineProfileStatus=ExaminerProfileStatusEnum;
        const examinerMonitorStatus=ExaminerMonitorStatusEnum;
        const examinerLastSessionStatus=ExaminerLastSessionStatusEnum;
        const examienrCertificationStatus=ExaminerCertificationStatusEnum;


        const state = reactive<{
            regionList: GetAllRegionRep[],
          }>({
            regionList: new Array<GetAllRegionRep>(),
          })
      
        const systemStatusEnum = SystemStatusEnum;
        const searchDefaultParams= <SearchCertificationRecordsQuery>{ module: ModuleEnum.Speaking,certificationStartDate:undefined,certificationEndDate:undefined }

        const computedData = computed(()=>{
            let certificationDateTemp =tableListState.queryParams.certificationStartDate;
            return {
                certificationData:certificationDateTemp?[moment(certificationDateTemp),moment(certificationDateTemp)]:[]
            }
        })

        async function getRegionList() {
            state.regionList = await _Client.regionClient.getAll(true)
        }

        async function traningDateCalendarChange(){
            console.log(123)
        }

        function goToDetailPage(id:string){
            push(`examiner-records/${id}`)
        }

        const tableListState = reactive<{
            tableLoading:boolean,
            queryCertificationDateParam:string[]|undefined,
            queryParams:SearchCertificationRecordsQuery,
            tableSource :PagedResultOfSerchCertificationRecordsRep,
            searchExaminerRecords:Function,
            search:Function,
            reset:Function,
            certificationDateChange:Function,
            traningDateChange:Function
        }>({
            tableLoading:false,
            queryParams: new SearchCertificationRecordsQuery(<SearchCertificationRecordsQuery>{pageIndex:1,module:ModuleEnum.Speaking}),
            tableSource: new PagedResultOfSerchCertificationRecordsRep(),
            queryCertificationDateParam:undefined,

            searchExaminerRecords:async ()=>{
                try {
                    tableListState.tableLoading = true
                    tableListState.queryParams.certificationStartDate = tableListState.queryParams.certificationStartDate === undefined ? undefined : addTimezoneByDbNameForce(tableListState.queryParams.certificationStartDate, 'Etc/GMT-0')
                    tableListState.queryParams.certificationEndDate = tableListState.queryParams.certificationEndDate === undefined ? undefined : addTimezoneByDbNameForce(tableListState.queryParams.certificationEndDate, 'Etc/GMT-0')
                    tableListState.tableSource = await _Client.certificationRecordClient.search(tableListState.queryParams)
                }
                finally {
                    tableListState.tableLoading = false
                }
            },

            search:async ()=>{
                tableListState.queryParams.pageIndex=1;
                await tableListState.searchExaminerRecords();
            },

            reset: async ()=>{
                tableListState.queryParams=new SearchCertificationRecordsQuery(<SearchCertificationRecordsQuery>{pageIndex:1})
                // 前后端参数结构不一致，单独reset
                tableListState.queryCertificationDateParam = undefined;
                tableListState.queryParams.module = ModuleEnum.Speaking;
                await tableListState.searchExaminerRecords();
            },

            certificationDateChange: async (dates:Array<Date>,dateStrings:Array<string>)=> {
                if(dates != null){
                    tableListState.queryParams.certificationStartDate=dates[0]
                    tableListState.queryParams.certificationEndDate=dates[1]
                }else{
                    tableListState.queryParams.certificationStartDate=undefined
                    tableListState.queryParams.certificationEndDate=undefined
                }
                await tableListState.searchExaminerRecords();
            },

            traningDateChange:async(date:any,dateString:any) =>{
                if(date){
                    tableListState.queryParams.trainingDate=date
                }
                await tableListState.searchExaminerRecords();
            }
        })

        const logOperation = () => {
            const logDetailRef = ref(null)
            const logState = reactive<{ logSearchParames: GetCertificationRecordLogByIdQuery, logDetail: LogDetailModel }>({
              logSearchParames: new GetCertificationRecordLogByIdQuery(),
              logDetail: new LogDetailModel()
            })

            async function showLogDialog(certificationId: string) {
            //   let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.ViewLog}`, examinerHub!.officeId)
            //   if (!hasEditPermission) { return }
      
              logState.logSearchParames.certificationId = certificationId;
              logState.logSearchParames.pageIndex =1
              await getLogList()
            }
            async function getLogList() {
              try {
                let logDetail = JSON.parse(JSON.stringify(await _Client.certificationRecordClient.getCertificationRecordLogById(logState.logSearchParames)))
                if(!logDetail){
                    return;
                }
                logDetail.list = logDetail.list?.map((logItem: { auditTime: Date; operation: string; userName: string; }) => {
                  return new LogModel({
                    createdTime: logItem.auditTime,
                    operation: logItem.operation,
                    userName: logItem.userName
                  })
                })
                logState.logDetail = new LogDetailModel(logDetail)
              }
              finally {
                (logDetailRef.value as any).showLogModal = true
              }
            }
      
            return { logDetailRef, ...toRefs(logState), showLogDialog, getLogList }
          }

        onActivated(async ()=>{
            await tableListState.searchExaminerRecords();
        })

        onMounted(async () => {
            await getRegionList();
            await tableListState.searchExaminerRecords();
        })

        return {
            timeMoment,
            moduleEnum,
            etLevel,
            workStatus,
            systemStatus,
            examineProfileStatus,
            examinerMonitorStatus,
            examinerLastSessionStatus,
            examienrCertificationStatus,
            state,
            systemStatusEnum,
            tableColumns,
            searchParamsIsDisplay,
            tableListState,
            traningDateCalendarChange,
            goToDetailPage,
            computedData,
            ...logOperation()
        }
    }
})